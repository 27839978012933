// setConfigurations.js
'use strict';
import getOperationData from '../selector/getOperationData.js';
import updateConfigurations from './updateConfigurations.js';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Handle configurations
 * @kind action
 * @param {object} {configData} - configuration payload
 * {
 *   "configuration-group-id": "global",
 *   "configuration-groups": {
 *     "global": ["default", "nsfw"]
 *   }
 * }
 * @param {string} {priority} - remote config priority
 * @return {Promise} Action promise.
 */
const setConfigurations =
  ({ configData, priority }) =>
  async (dispatch, getState) => {
    if (!configData) {
      return dispatch({ type: '' });
    }

    const configurationGroups = configData['configuration-groups'] || {};
    const defaultConfigKey = configData['configuration-group-id'];
    const currentConfigKey =
      getOperationData(getState(), ['config'], 'currentConfigKey') ||
      defaultConfigKey;

    const isValidCurrentConfigkey =
      Object.keys(configurationGroups).includes(currentConfigKey);
    // configKey is configuration group. e.g. global.
    const configKey = isValidCurrentConfigkey
      ? currentConfigKey
      : defaultConfigKey;

    const isConfigurationsSetting = getOperationData(
      getState(),
      ['config', 'remoteConfigMerging'],
      priority
    );

    if (isConfigurationsSetting) {
      return dispatch({ type: '' });
    }

    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['config'],
        data: {
          currentConfigKey: configKey,
          defaultConfigKey,
          configurationGroups: {
            [priority]: configurationGroups,
          },
          remoteConfigMerged: {
            [priority]: false,
          },
          remoteConfigMerging: {
            [priority]: true,
          },
        },
      },
    });
    await dispatch(updateConfigurations({ configKey }));
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['config'],
        data: {
          remoteConfigMerged: {
            [priority]: true,
          },
          remoteConfigMerging: {
            [priority]: false,
          },
        },
      },
    });
  };

export default setConfigurations;
